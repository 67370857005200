import { useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import Carousel, { CarouselApi } from '@/components/carousel'
import { cls, isMobile, whisper } from '@/utils'
import Button from '@/components/button'
import IconImage2Video from '@haiper/icons-svg/icons/outline/multi-media.svg'
import IconDownload from '@haiper/icons-svg/icons/outline/download.svg'
import IconLayout3Rows from '@haiper/icons-svg/icons/outline/carussel.svg'
import IconLayoutGrid from '@haiper/icons-svg/icons/outline/layout-grid-2.svg'
import IconLeftArrow from '@haiper/icons-svg/icons/outline/arrow-left.svg'
import IconRightArrow from '@haiper/icons-svg/icons/outline/arrow-right.svg'
import Tooltip from '@/components/tooltip'
import { creationInputAtom } from '@/atoms'
import { Creation, CreationModeEnum } from '@/types'
import Tabs from '@/components/tabs'
import useAmplitude from '@/hooks/useAmplitude'
import Image from '../image'

export interface ScreenImageProps {
  className?: string
  creation: Creation
  onCloseModal?: () => void
  blurBg?: boolean
}

export default function ScreenImage({ onCloseModal, creation, blurBg }: ScreenImageProps) {
  const { track } = useAmplitude()
  const [layoutMode, setLayoutMode] = useState<'list' | 'grid'>(isMobile() ? 'list' : 'grid')
  const creationsLayoutOptions = useMemo(() => {
    return [
      {
        value: 'grid',
        label: <IconLayoutGrid className={cls('size-6')} aria-label='Grid' />,
      },
      {
        value: 'list',
        label: <IconLayout3Rows className={cls('size-6')} aria-label='List' />,
      },
    ]
  }, [])
  const [innerApi, setInnerApi] = useState<CarouselApi>()
  const initIndex = useSearchParams()?.get('index')
  const [currentIndex, setCurrentIndex] = useState(Number(initIndex) || 0)
  const setCreationInput = useSetAtom(creationInputAtom)
  const handleSetApi = useCallback(
    (api: CarouselApi) => {
      setInnerApi(api)
    },
    [setInnerApi],
  )

  useEffect(() => {
    if (innerApi) {
      if (!innerApi) {
        return
      }

      innerApi.on('select', () => {
        setCurrentIndex(innerApi.selectedScrollSnap())
      })
    }
  }, [innerApi])

  const outputs = creation?.outputs

  const itemRender = (data: { media_url: string }) => {
    return (
      <div className='flex justify-center h-full md:max-w-calc-100vw-minus-64px max-w-calc-100vw-minus-32px'>
        <Image className='block rounded-md object-contain' src={data.media_url} alt='' />
      </div>
    )
  }

  const moveTo = (index: number) => {
    innerApi?.scrollTo(index)
    setCurrentIndex(index)
  }

  const renderBottom = (data: { media_url: string }[]) => {
    return (
      <div className='flex fixed bottom-8 gap-2 h-3 items-center'>
        {data.map((item, index) => (
          <i
            key={index}
            className={cls(
              'block size-2 rounded-full bg-icon-disable cursor-pointer',
              currentIndex === index ? 'size-3 bg-icon-interactive' : '',
            )}
            onClick={() => moveTo(index)}
          />
        ))}
      </div>
    )
  }

  const downloadImage = async (item?: { media_url: string }) => {
    if (outputs) {
      const url = item?.media_url || outputs[currentIndex].media_url
      const response = await fetch(url)
      const blob = await response.blob()
      const urlBlob = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = urlBlob
      a.download = 'image.png'
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(urlBlob)
      document.body.removeChild(a)
    }
  }
  const openText2Image = (item?: { media_url: string }) => {
    if (creation && outputs) {
      const url = item?.media_url || outputs[currentIndex].media_url
      setCreationInput((prev) => ({
        ...prev,
        mode: CreationModeEnum.AnimateHD,
        creation: {
          ...creation,
          settings: { ...creation.settings, resolution: 720 },
        },
        expanded: true,
        focusing: true,
        img: url,
      }))
      onCloseModal?.()
    }
  }
  const imageData = creation?.outputs || []

  const handleClick = (item: { media_url: string; id?: string }, index: number) => {
    track('click:creations:image:show-screen', {
      creation_id: creation?.creation_id || '',
      image_id: item.id || '',
    })
    setCurrentIndex(index)
    setLayoutMode('list')
  }

  const nextRender = () => {
    return (
      <Button
        variant='outline'
        size='icon'
        className={cls(
          'absolute size-10 md:size-12 border-border border-solid bg-surface hover:bg-surface-hover text-icon right-0 md:right-10 top-1/2 -translate-y-1/2 rounded-lg border-2 border-b-4',
          innerApi?.canScrollNext() ? '' : 'cursor-not-allowed',
          isMobile() ? 'bg-transparent active:bg-transparent text-white border-0' : '',
        )}
        disabled={!innerApi?.canScrollNext()}
        onClick={() => moveTo(currentIndex + 1)}
      >
        <IconRightArrow className='size-6' />
      </Button>
    )
  }
  const previousRender = () => {
    return (
      <Button
        variant={isMobile() ? 'transparent' : 'outline'}
        size='icon'
        className={cls(
          'absolute size-10 md:size-12 border-border border-solid bg-surface hover:bg-surface-hover text-icon left-0 md:left-10 top-1/2 -translate-y-1/2 rounded-lg border-2 border-b-4',
          innerApi?.canScrollPrev() ? '' : 'cursor-not-allowed',
          isMobile() ? 'bg-transparent active:bg-transparent text-white border-0' : '',
        )}
        disabled={!innerApi?.canScrollPrev()}
        onClick={() => moveTo(currentIndex - 1)}
      >
        <IconLeftArrow className='size-6' />
      </Button>
    )
  }

  useEffect(() => {
    const keyDownEvent = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        innerApi?.scrollPrev()
      }
      if (e.key === 'ArrowRight') {
        innerApi?.scrollNext()
      }
    }
    window.addEventListener('keydown', keyDownEvent)
    return () => {
      window.removeEventListener('keydown', keyDownEvent)
    }
  }, [innerApi])

  if (creation?.is_illegal) {
    return null
  }

  return (
    <>
      <div
        className={cls(
          'w-full flex flex-col gap-6 relative flex-1 items-center',
          layoutMode === 'list' ? 'mb-[108px]' : '',
        )}
      >
        {isMobile() ? null : (
          <Tabs
            className='flex mx-auto'
            value={layoutMode}
            items={creationsLayoutOptions}
            onChange={(mode) => {
              whisper('tabs.onchange, mode is: ', mode)
              setCurrentIndex(0)
              setLayoutMode(mode as 'list' | 'grid')
            }}
          />
        )}
        {layoutMode === 'grid' ? (
          <div className='absolute inset-0 flex items-center justify-center pointer-events-none'>
            <div
              className={cls(
                'select-none mx-auto w-full max-w-calc-100vh-minus-208px-16/9 max-h-calc-100vh-minus-208px grid grid-cols-2 grid-rows-2 gap-[2px] rounded-sm sm:rounded-md overflow-hidden aspect-video bg-transparent pointer-events-auto',
                creation?.is_illegal ? 'cursor-default' : 'cursor-pointer',
              )}
            >
              {imageData.map((item, index) => (
                <div key={index} className='relative group overflow-hidden'>
                  <div
                    className={cls(
                      'absolute inset-0 bg-cover bg-center bg-no-repeat backdrop-blur-[50px] z-0 blur-md',
                      blurBg ? '' : 'bg-surface-base',
                    )}
                    style={
                      blurBg
                        ? {
                            backgroundImage: `url(${item.media_url})`,
                          }
                        : undefined
                    }
                    onClick={() => handleClick(item, index)}
                  >
                  </div>
                  <Image
                    className={cls('object-scale-down size-full m-auto absolute', blurBg ? '' : 'bg-surface-base')}
                    src={item.media_url}
                    alt=''
                    onClick={() => handleClick(item, index)}
                  />
                  <div className='hidden group-hover:flex bg-gray-900-opacity-80 rounded-full h-8 absolute bottom-2 items-center left-[50%] translate-x-[-50%] px-3 gap-2'>
                    <Tooltip
                      trigger={
                        <Button
                          className='mt-1 h-6 w-6 p-0 rounded-sm bg-transparent hover:bg-gray-700 active:bg-gray-700'
                          onClick={() => openText2Image(item)}
                        >
                          <IconImage2Video className='size-4 text-icon-on-color' />
                        </Button>
                      }
                    >
                      <div className='text-text-on-color'>Image to Video</div>
                    </Tooltip>
                    {/* <Button
              className='h-6 w-6 p-0 rounded-sm bg-transparent'
              onClick={() => likeImage(index)}
            >
              {item.like ? (
                <IconHeartSolid className='size-4 text-border-critical-hover' />
              ) : (
                <IconHeartOutline className='size-4' />
              )}
            </Button> */}
                    <Tooltip
                      trigger={
                        <Button
                          className='mt-1 h-6 w-6 p-0 rounded-sm bg-transparent hover:bg-gray-700 active:bg-gray-700'
                          onClick={() => downloadImage(item)}
                        >
                          <IconDownload className='size-4 text-icon-on-color' />
                        </Button>
                      }
                    >
                      <div className='text-text-on-color'>Download</div>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Carousel
            dataSource={outputs}
            itemRenderer={itemRender}
            setApi={handleSetApi}
            className='flex-1 relative'
            itemClassName='h-full'
            carouselClassname='absolute h-full'
            nextRenderer={nextRender}
            previousRenderer={previousRender}
            footerRenderer={renderBottom}
            startIndex={Number(currentIndex) || 0}
          />
        )}
      </div>
      {layoutMode === 'list' ? (
        <div className='fixed bottom-16 left-1/2 translate-x-[-50%] h-12 flex items-center border-2 border-b-4 bg-surface rounded-full px-6 box-content'>
          <Tooltip
            open={isMobile() ? false : undefined}
            trigger={
              <Button autoFocus={false} variant='transparent' className='size-10 p-2' onClick={() => openText2Image()}>
                <IconImage2Video className='text-icon' />
              </Button>
            }
          >
            <div className='text-text-on-color'>Image to Video</div>
          </Tooltip>
          {/* <Button
          className='h-6 w-6 p-0 rounded-sm bg-transparent'
          onClick={() => likeImage(index)}
        >
          {item.like ? (
            <IconHeartSolid className='size-4 text-border-critical-hover' />
          ) : (
            <IconHeartOutline className='size-4' />
          )}
        </Button> */}
          <Tooltip
            trigger={
              <Button variant='transparent' className='size-10 p-2' onClick={() => downloadImage()}>
                <IconDownload className='text-icon' />
              </Button>
            }
          >
            <div className='text-text-on-color'>Download</div>
          </Tooltip>
        </div>
      ) : null}
    </>
  )
}
