import { activeTemplateIdAtom, allowedNSFWCreationIdsAtom, creationInputAtom } from '@/atoms'
import Button from '@/components/button'
import { Creation, CreationModeEnum, GalleryItemSource } from '@/types'
import { calculateAspectRatio, cls } from '@/utils'
import IconImage2Video from '@haiper/icons-svg/icons/outline/multi-media.svg'
import IconNSFW from '@haiper/icons-svg/icons/outline/error.svg'
import IconDownload from '@haiper/icons-svg/icons/outline/download.svg'
import IconFullscreen from '@haiper/icons-svg/icons/outline/fullscreen.svg'
import IconBlock from '@haiper/icons-svg/icons/outline/block.svg'
import { useAtom, useSetAtom } from 'jotai'
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import useAmplitude from '@/hooks/useAmplitude'
import Tooltip from '@/components/tooltip'
import ScreenImage from '@/components/screen-image'
import { Modal } from '@/components/modal'
import { useRouter } from 'next/navigation'
import Image from '@/components/image'
import NSFW from '@/components/nsfw'
import Illegal from '@/components/illegal'

interface MainCardProps {
  data: Creation
  source: GalleryItemSource
  onClick: () => void
  blurBg?: boolean
  waterfall?: boolean
}

const MainCard = ({ data, source, onClick, blurBg, waterfall }: MainCardProps) => {
  const { track } = useAmplitude()
  const [imageData, setImageData] = useState(data.outputs || [])
  const [screenOpen, setScreenOpen] = useState(false)
  const setCreationInput = useSetAtom(creationInputAtom)
  const [allowedNSFWCreationIds, setAllowedNSFWCreationIds] = useAtom(allowedNSFWCreationIdsAtom)
  const nsfw = data.is_nsfw
  const creationId = data.creation_id
  const illegal = data.is_illegal

  const showNSFW = useMemo(() => {
    return !!nsfw && !allowedNSFWCreationIds.includes(creationId) && !illegal // don't show NSFW mask if illegal
  }, [allowedNSFWCreationIds, nsfw, illegal, creationId])
  const router = useRouter()

  const isInCreationsOrCollections = source === 'creations' || source === 'collection'

  const setActiveTemplateId = useSetAtom(activeTemplateIdAtom)

  const openText2Image = useCallback(
    (item: { media_url: string; id?: string }) => {
      track('click:creations:image:text2image', {
        creation_id: data.creation_id,
        image_id: item.id || '',
      })

      setCreationInput((prev) => ({
        ...prev,
        mode: CreationModeEnum.AnimateHD,
        creation: {
          ...data,
          settings: { ...data.settings, resolution: 720, duration: 4 },
        },
        expanded: true,
        focusing: true,
        img: item.media_url,
      }))

      setActiveTemplateId(null)

      if (source !== 'creations' && source !== 'explore') {
        router.push('/creations')
      }
    },
    [data, router, setCreationInput, source, track, setActiveTemplateId],
  )

  const downloadImage = async (item: { media_url: string; id?: string }) => {
    track('click:creation:image:download', {
      image_id: item.id,
      image_url: item.media_url,
    })
    const response = await fetch(item.media_url)
    const blob = await response.blob()
    const urlBlob = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = urlBlob
    a.download = 'image.png'
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(urlBlob)
    document.body.removeChild(a)
  }

  const openScreen = (item: { media_url: string; id?: string }) => {
    track('click:creations:image:show-screen', {
      creation_id: data.creation_id,
      image_id: item.id || '',
    })
    setScreenOpen(true)
  }

  const handleClick = (item: { media_url: string; id?: string }) => {
    track('click:creations:image:show-screen', {
      creation_id: data.creation_id,
      image_id: item.id || '',
    })
    if (!isInCreationsOrCollections) {
      onClick?.()
    } else {
      track('click:creations:image:show-screen', {
        creation_id: data.creation_id,
        image_id: item.id || '',
      })
      setScreenOpen(true)
    }
  }

  useEffect(() => {
    setImageData(data.outputs || [])
  }, [data.outputs])

  const coverAspectStyle: CSSProperties = useMemo(() => {
    if (!waterfall) {
      return {}
    }

    const { width, height } = data?.spec ?? {}
    const aspectRatio = calculateAspectRatio(width, height)
    return {
      aspectRatio,
    }
  }, [data?.spec, waterfall])

  const hideNSFWMask = useCallback(
    (e: any) => {
      e?.stopPropagation?.()
      e?.preventDefault?.()

      setAllowedNSFWCreationIds((old) => {
        if (old.includes(creationId)) {
          return old
        }
        return [...old, creationId]
      })
    },
    [setAllowedNSFWCreationIds, creationId],
  )

  const renderMask = () => {
    if (showNSFW) {
      return <NSFW outputType='image' onView={hideNSFWMask} />
    }
    if (illegal) {
      return <Illegal creationId={creationId} />
    }
  }

  if (!isInCreationsOrCollections) {
    return (
      <div
        className={cls(
          'select-none size-full relative rounded-sm sm:rounded-md overflow-hidden bg-transparent',
          !waterfall && 'aspect-video',
          data?.is_illegal ? 'cursor-default' : 'cursor-pointer',
        )}
        onClick={() => handleClick({ media_url: data.output_url || '' })}
      >
        <div className='relative group overflow-hidden size-full'>
          <div
            className={cls(
              'absolute inset-0 bg-cover bg-center bg-no-repeat backdrop-blur-[50px] z-0 blur-md',
              blurBg ? '' : 'bg-surface-base',
            )}
            style={
              blurBg
                ? {
                    backgroundImage: `url(${data.output_url})`,
                  }
                : undefined
            }
          >
          </div>
          <Image
            className={cls(
              'size-full m-auto absolute',
              blurBg ? '' : 'bg-surface-base',
              waterfall ? 'object-cover object-top' : 'object-scale-down',
            )}
            src={data.output_url}
            alt=''
            style={coverAspectStyle}
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className={cls(
        'select-none size-full relative grid grid-cols-2 grid-rows-2 gap-[2px] rounded-sm sm:rounded-md overflow-hidden bg-transparent',
        !waterfall && 'aspect-video',
        data?.is_illegal ? 'cursor-default' : 'cursor-pointer',
      )}
      style={coverAspectStyle}
    >
      {illegal || showNSFW
        ? null
        : imageData.map((item, index) => (
          <div key={index} className='relative group overflow-hidden'>
            <div
              className='absolute inset-0 bg-cover bg-center bg-no-repeat backdrop-blur-[50px] z-0 blur-md'
              style={
                  blurBg
                    ? {
                        backgroundImage: `url(${item.media_url})`,
                      }
                    : undefined
                }
              onClick={() => handleClick(item)}
              >
            </div>
            <Image
              className={cls(
                  'size-full m-auto absolute',
                  blurBg ? '' : 'bg-surface-base',
                  waterfall ? 'object-cover object-top' : 'object-scale-down',
                )}
              src={item.media_url}
              alt=''
              style={coverAspectStyle}
              onClick={() => handleClick(item)}
              />
            <div className='hidden group-hover:flex bg-gray-900-opacity-80 rounded-full h-8 absolute bottom-2 items-center left-[50%] translate-x-[-50%] px-3 gap-2'>
              <Tooltip
                withPortal={false}
                trigger={
                  <Button
                    className='mt-1 h-6 w-6 p-0 rounded-sm bg-transparent hover:bg-gray-700 active:bg-gray-700'
                    onClick={() => openText2Image(item)}
                    >
                    <IconImage2Video className='size-4 text-icon-on-color' />
                  </Button>
                  }
                >
                <div className='text-text-on-color'>Image to Video</div>
              </Tooltip>
              <Tooltip
                withPortal={false}
                trigger={
                  <Button
                    className='mt-1 h-6 w-6 p-0 rounded-sm bg-transparent hover:bg-gray-700 active:bg-gray-700'
                    onClick={() => downloadImage(item)}
                    >
                    <IconDownload className='size-4 text-icon-on-color' />
                  </Button>
                  }
                >
                <div className='text-text-on-color'>Download</div>
              </Tooltip>
            </div>
            <Button
              className='hidden group-hover:flex h-8 w-8 p-0 rounded-full bg-gray-900-opacity-80 absolute bottom-2 right-2 hover:bg-gray-700 active:bg-gray-700'
              onClick={() => openScreen(item)}
              >
              <IconFullscreen alt='toggle fullscreen' className='size-4 text-icon-on-color' />
            </Button>
          </div>
          ))}
      {renderMask()}
      <Modal className='p-4 md:p-10' open={screenOpen} onOpenChange={(open) => setScreenOpen(open)}>
        <ScreenImage creation={data} onCloseModal={() => setScreenOpen(false)} />
      </Modal>
    </div>
  )
}

export default MainCard
