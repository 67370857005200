'use client'

import {
  Carousel as BaseCarousel,
  CarouselProps as BaseCarouselProps,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel'
import { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { cls, whisper } from '@/utils'
import Empty from '@/components/empty'
import { twMerge } from 'tailwind-merge'
import IconArrowLeft from '@haiper/icons-svg/icons/outline/arrow-left.svg'
import IconArrowRight from '@haiper/icons-svg/icons/outline/arrow-right.svg'
import Button from '../button'
import { isNil } from 'lodash-es'

export type { CarouselApi }

export interface CarouselProps<T = any> extends BaseCarouselProps {
  autoPlay?: boolean
  scrollable?: boolean
  duration?: number // the duration of the carousel, default is 20
  idField?: string // the field name of the id, default is 'id'
  className?: string
  carouselClassname?: string
  itemClassName?: string
  thumbClassName?: string
  nextButtonClassName?: string
  prevButtonClassName?: string
  startIndex?: number
  footerRenderer?: (data: T[]) => ReactNode
  dataSource?: T[] | null
  footerVariant?: 'dash' | 'dot'
  itemRenderer: (item: T, index: number) => ReactNode
  previousRenderer?: () => ReactNode
  nextRenderer?: () => ReactNode
}

export interface CarouselRef {
  api: CarouselApi | undefined
  activeIndex: number
}

function Carousel<T = any>({
  autoPlay,
  className,
  carouselClassname,
  scrollable,
  itemClassName,
  thumbClassName,
  duration = 20,
  idField = 'id',
  dataSource,
  setApi,
  itemRenderer,
  nextButtonClassName,
  previousRenderer,
  nextRenderer,
  prevButtonClassName,
  footerRenderer,
  footerVariant = 'dash',
  startIndex,
  ...props
}: CarouselProps<T>) {
  const [innerApi, setInnerApi] = useState<CarouselApi>()

  const opts = useMemo(() => {
    const result = {
      duration,
      align: 'center' as const,
      startIndex,
    }
    if (isNil(startIndex)) {
      delete result.startIndex
    }
    return result
  }, [duration, startIndex])

  const updateActiveIndex = useCallback(() => {
    if (innerApi) {
      setActiveIndex(innerApi.selectedScrollSnap())
    }
  }, [innerApi])

  const handleSetApi = useCallback(
    (api: CarouselApi) => {
      setInnerApi(api)
      setApi?.(api)
    },
    [setApi],
  )

  useEffect(() => {
    if (innerApi) {
      innerApi.on('select', updateActiveIndex)
    }

    return () => {
      if (innerApi) {
        innerApi.off('select', updateActiveIndex)
      }
    }
  }, [innerApi, updateActiveIndex])

  const isEmpty = !(dataSource?.length && dataSource?.length > 0)

  const thumbWidth = Math.ceil(200 / (dataSource?.length || 1))
  const [activeIndex, setActiveIndex] = useState(0)

  const thumbStyle: CSSProperties = useMemo(() => {
    return {
      width: thumbWidth,
      left: thumbWidth * activeIndex,
    }
  }, [thumbWidth, activeIndex])

  const scrollTo = useCallback(
    (index: number) => {
      innerApi?.scrollTo(index)
      setActiveIndex(index)
    },
    [innerApi],
  )

  const scrollPrev = useCallback(() => {
    if (activeIndex > 0) {
      const newIndex = Math.max(activeIndex - 1, 0)
      scrollTo(newIndex)
    } else if (activeIndex === 0) {
      const newIndex = Math.max(0, (dataSource?.length ?? 0) - 1)
      scrollTo(newIndex)
    }
  }, [scrollTo, activeIndex, dataSource?.length])

  const scrollNext = useCallback(() => {
    if (activeIndex < (dataSource?.length || 0) - 1) {
      const newIndex = Math.min(activeIndex + 1, (dataSource?.length || 0) - 1)
      scrollTo(newIndex)
    } else if (activeIndex === (dataSource?.length || 0) - 1) {
      scrollTo(0)
    }
  }, [scrollTo, activeIndex, dataSource])

  const renderFooter = useCallback(() => {
    if (footerRenderer) {
      return footerRenderer(dataSource || [])
    }

    if (footerVariant === 'dash') {
      return (
        <div
          className={cls('relative w-50 h-1 bg-border rounded-full', isEmpty ? 'hidden' : '', thumbClassName)}
          aria-label='scroll'
        >
          <div className={cls('absolute bg-icon-interactive h-full rounded-full')} style={thumbStyle} />
        </div>
      )
    } else if (footerVariant === 'dot') {
      return (
        <div className='w-full h-10 flex justify-center items-center gap-6'>
          <Button
            variant='outline'
            className='size-10 p-0'
            // disabled={activeIndex === 0}
            onClick={scrollPrev}
          >
            <IconArrowLeft className='size-6 text-icon' />
          </Button>
          <div className='flex gap-2 h-3 items-center'>
            {dataSource?.map((item, index) => (
              <i
                key={index}
                className={cls(
                  'block size-2 rounded-full bg-icon-disable cursor-pointer',
                  activeIndex === index ? 'size-3 bg-icon-interactive' : '',
                )}
                onClick={() => scrollTo(index)}
              />
            ))}
          </div>
          <Button
            variant='outline'
            className='size-10 p-0'
            // disabled={activeIndex === (dataSource?.length || 0) - 1}
            onClick={scrollNext}
          >
            <IconArrowRight className='size-6 text-icon' />
          </Button>
        </div>
      )
    }
  }, [
    footerRenderer,
    dataSource,
    scrollTo,
    activeIndex,
    footerVariant,
    isEmpty,
    thumbClassName,
    thumbStyle,
    scrollNext,
    scrollPrev,
  ])

  return (
    <div className={cls('flex w-full flex-col gap-4 items-center h-full', className)} aria-label='contents'>
      <BaseCarousel
        pauseOnHover
        autoPlay={autoPlay}
        setApi={handleSetApi}
        className={twMerge('w-full relative h-0 flex-1', carouselClassname)}
        opts={opts}
        {...props}
      >
        <CarouselContent className={cls('ml-0 h-full', scrollable && 'overflow-auto')}>
          {dataSource?.length ? (
            dataSource?.map((item: T, index) => {
              return (
                <CarouselItem key={[(item as any)?.[idField] ?? '', index].join()} className={itemClassName}>
                  {itemRenderer?.(item, index)}
                </CarouselItem>
              )
            })
          ) : (
            <div className='w-full flex justify-center'>
              <Empty />
            </div>
          )}
        </CarouselContent>
        {previousRenderer ? previousRenderer() : <CarouselPrevious className={cls(prevButtonClassName)} />}
        {nextRenderer ? nextRenderer() : <CarouselNext className={cls(nextButtonClassName)} />}
      </BaseCarousel>
      {renderFooter()}
    </div>
  )
}

export default Carousel
