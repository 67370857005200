import { forwardRef, useMemo, useState } from 'react'
import IconEyeSlash from '@haiper/icons-svg/icons/outline/eye-off.svg'
import { inputType2Color, inputType2Icon, type2Color, type2Icon } from '@/constants'
import IconInfo from '@haiper/icons-svg/icons/outline/info-circle.svg'
import Dialog from '@/components/dialog'
import {
  Creation,
  GenerationConfig,
  ModelVersion,
  OutputType,
  PoNVoid,
  TemplateCategory,
  TemplateGenerationParams,
} from '@/types'
import { cls, whisper } from '@/utils'
import CreationInfo, { getPromptPrefix } from '@/components/creation-info'
import { cva, type VariantProps } from 'class-variance-authority'
import { useAtomValue } from 'jotai'
import { creationCacheAtom } from '@/atoms'
import { useCachedTemplateCategories } from '@/hooks/useTemplateCategories'
import { useCachedTemplate } from '@/hooks/useTemplate'
import TemplateIcon from '@/components/templates/icon'
import Link from '@/components/link'
// import { useCachedOutput } from '@/hooks/useOutput'

const promptVariants = cva('flex gap-2 max-w-full items-center', {
  variants: {
    variant: {
      outline: '',
      transparent: '',
    },
    size: {
      md: 'h-10',
      sm: 'h-8',
      lg: 'h-12',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

const iconVariants = cva('shrink-0 text-icon-on-color ', {
  variants: {
    size: {
      md: 'size-6',
      sm: 'size-5',
      lg: 'size-7',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

const textVariants = cva(
  'text-text relative w-full border-solid border-border rounded-full flex items-center justify-between bg-surface hover:bg-surface-hover active:bg-surface-hover cursor-pointer',
  {
    variants: {
      variant: {
        outline: 'border border-border pr-1',
        transparent: 'border-none border-0 pr-0',
      },
      size: {
        md: 'h-10',
        sm: 'h-8',
        lg: 'h-12',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'outline',
    },
  },
)

interface PromptProps
  extends VariantProps<typeof textVariants>,
    Pick<
      Creation,
      'input_type' | 'prompt' | 'create_time' | 'settings' | 'creation_id' | 'type' | 'is_public' | 'output_type'
    > {
  className?: string
  iconClassName?: string
  config: Creation['config'] | TemplateGenerationParams['config']
  onClick?: (e?: any) => PoNVoid
  model_version?: ModelVersion
}

export const Prompt = forwardRef<HTMLDivElement, PromptProps>(
  (
    {
      prompt,
      input_type,
      output_type,
      create_time,
      settings,
      creation_id,
      className,
      type,
      size,
      config,
      variant,
      onClick,
      is_public,
      model_version,
      iconClassName,
    },
    ref,
  ) => {
    const templateId = (config as TemplateGenerationParams['config'])?.template_id
    const isTemplateGeneration = type === 'template' && !!templateId
    const [showVideoInfo, setShowVideoInfo] = useState(false)

    const { data: templateCategories } = useCachedTemplateCategories()
    const { data: template } = useCachedTemplate((config as TemplateGenerationParams['config'])?.template_id)
    const category = templateCategories?.find((category: TemplateCategory) => category.category === template?.category)

    // const outputId = output_type === 'video' ? creation_id : undefined
    // const { data: outputRes } = useCachedOutput(outputId)
    // const output = outputRes?.data

    // TODO: check whether output is needed here. If not, remove it. it's for the ppurpose of top-left icon display, may be replaced by creation detail.

    const ModeIcon = type2Icon[type] ?? inputType2Icon[input_type] ?? inputType2Icon.video

    const creationCache = useAtomValue(creationCacheAtom)

    const isPublic = creationCache[creation_id]?.is_public ?? is_public ?? true

    const fileCount = Math.max(
      1,
      Number(
        input_type === 'image'
          ? (config as GenerationConfig)?.source_images?.filter(Boolean)?.length ?? 1
          : input_type === 'video'
            ? 1
            : 0,
      ) || 0,
    )

    const prefix = getPromptPrefix({
      type,
      input_type,
      prompt,
      config,
    })

    const templateLink = useMemo(() => {
      if (templateId) {
        return `/template/${templateId}`
      }
      return '#'
    }, [templateId])

    const icon = isTemplateGeneration ? (
      <Link href={templateLink} className='flex items-center gap-2'>
        <TemplateIcon src={category?.icon} size='sm' />
        <span className='whitespace-nowrap text-body-md tracking-15'>{template?.name}</span>
      </Link>
    ) : (
      <div
        className={cls(
          'h-full aspect-square rounded-full flex items-center justify-center shrink-0',
          type2Color[type] ?? inputType2Color[input_type] ?? inputType2Color.video,
          iconClassName,
        )}
      >
        <ModeIcon alt={input_type} className={cls(iconVariants({ size }), iconClassName)} />
      </div>
    )

    return (
      <div
        ref={ref}
        aria-label='header'
        className={cls(promptVariants({ size, variant }), className)}
        onClick={onClick}
      >
        {icon}
        {input_type === 'text' && !prompt ? null : (
          <div
            className={cls(textVariants({ size, variant }))}
            onClick={() => {
              setShowVideoInfo(true)
            }}
          >
            <div className='flex items-center w-0 flex-1 select-none gap-2'>
              <span
                className={cls('text-text shrink-0 whitespace-pre truncate max-w-full', prefix ? 'pl-3' : 'pl-1')}
                aria-label='prefix'
              >
                {prefix}
              </span>
              {prompt ? (
                <span className='w-max truncate min-w-0' title={prompt} aria-label='prompt-text'>
                  {prompt}
                </span>
              ) : null}
            </div>
            <div className='flex items-center'>
              {!isPublic && (
                <div className='size-8 flex items-center justify-center'>
                  <IconEyeSlash className='size-5 text-icon-subdued' />
                </div>
              )}
              <div className='size-8 flex items-center justify-center'>
                <IconInfo className='size-5 text-icon-subdued' />
              </div>
            </div>
          </div>
        )}
        <Dialog
          open={showVideoInfo}
          title='Creation Info'
          className={cls('gap-4 flex flex-col max-h-[calc(100%-120px)] min-h-[210px]', className)}
          footer={null}
          childrenClassName='flex flex-col min-h-0'
          onOpenChange={setShowVideoInfo}
        >
          <CreationInfo
            className='min-h-0'
            promptClassName='mb-2 text-text-subdued'
            metaClassName='mb-4'
            input_type={input_type}
            prompt={prompt}
            settings={settings}
            creationId={creation_id}
            createTime={create_time}
            config={config as GenerationConfig}
            type={type}
            model_version={model_version}
            output_type={output_type}
          />
        </Dialog>
      </div>
    )
  },
)

Prompt.displayName = 'Prompt'
